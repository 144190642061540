import { useEffect } from 'react';
import Mixpanel from 'mixpanel-browser';

export const eventNames = {
  CLICK_ON_POSTER: 'click_on_poster',
  DISPLAY_FILTERS_MODAL: 'display_filters_modal',
  APPLY_FILTERS: 'apply_filters',
  SELECT_TIME_RANGE: 'select_time_range',
  PAGE_CHANGE: 'page_change',
  CLICK_ON_DATE_FILTER: 'click_on_date_filter',
  CLICK_ON_CINEMAS_FILTER: 'click_on_cinemas_filter',
  CLICK_ON_CARDS_FILTER: 'click_on_cards_filter',
  SELECT_DATE_FILTER: 'select_date_filter',
  SELECT_CINEMAS_FILTER: 'select_cinemas_filter',
  SELECT_CARDS_FILTER: 'select_cards_filter',
};

export const track = (payload) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('tracked', payload); // eslint-disable-line no-console
    return;
  }
  Mixpanel.track(payload.name.toLowerCase(), payload);
};

export const useTracking = (payload) => {
  useEffect(() => {
    track(payload);
  }, []);
};

export default track;
