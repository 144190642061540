import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { PageLoader } from 'components/Loader';
import Text from 'components/Text';
import { useQuery } from 'modules/hooks/useQuery';
import FilmShowings from './FilmShowings';

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    width: 960px;
    margin: auto;
  }
`;

const PosterImage = styled.img`
  max-width: 240px;
  border-radius: 5px;
  margin-top: 16px;
`;

const FilmDetails = ({ filters }) => {
  const { id } = useParams();
  const {
    data: { title, image, director, showings } = {},
    loading,
    error,
    success,
  } = useQuery({
    url: `/api/films/${id}.json`,
    params: {
      future: !localStorage.getItem('debug:fetch-all-showings'),
      ...filters,
    },
  });

  if (loading) return <PageLoader />;
  if (error) return <div>An error has happened</div>;
  return (
    <>
      {success && (
        <View>
          <PosterImage alt={title} src={image} />
          <Text
            className="mt-4 mb-1 mx-2 text-center text-2xl bold"
            color="light"
          >
            {title}
          </Text>
          <Text className="text-base mb-8 text-center" color="light">
            de
            <br />
            {director}
          </Text>
          <FilmShowings showings={showings} />
        </View>
      )}
    </>
  );
};

export default FilmDetails;
