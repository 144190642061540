import classnames from 'classnames';

const COLOR_LIGHT = 'light';
const COLOR_DARK = 'dark';

const Text = ({ color = COLOR_DARK, className, children }) => (
  <span
    className={classnames(
      {
        'text-neutral-100': color === COLOR_LIGHT,
        'text-neutral-800': color === COLOR_DARK,
      },
      className,
    )}
  >
    {children}
  </span>
);

export default Text;
