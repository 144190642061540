import { useEffect, useContext } from 'react';
import { track, eventNames } from 'modules/tracking/tracking';
import { useFilms } from 'modules/hooks/useFilms';
import { ScrollRestorationContext } from 'modules/contexts/scrollRestoration';
import { PageLoader } from 'components/Loader';
import FilmCard from './FilmCard';

const FilmsIndex = ({ filters }) => {
  const { films, loading, success, error } = useFilms({ filters });
  const { restoreScrollPosition, saveScrollPosition } = useContext(
    ScrollRestorationContext,
  );

  useEffect(() => {
    if (success) restoreScrollPosition();
  }, [success]);

  if (loading) return <PageLoader filterBarPresent />;
  if (error || !success) return <div>An error has happened</div>;

  return (
    <div className="flex flex-wrap justify-center">
      {films.map((film) => (
        <FilmCard
          key={film.hash_id}
          image={film.image}
          to={`/film/${film.hash_id}`}
          onClick={() => {
            saveScrollPosition();
            track({
              name: eventNames.CLICK_ON_POSTER,
              itemId: film.hash_id,
            });
          }}
        />
      ))}
    </div>
  );
};

export default FilmsIndex;
