import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Poster = styled(Link)`
  display: block;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  max-width: 250px;
  max-height: 325px;
  height: 325px;
  flex-basis: 50%;
  transition: linear 0.1s;

  @media (max-width: 490px) {
    height: 0;
    padding-top: 67%;
  }
  @media (min-width: 760px) {
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      box-shadow: 0px 0px 5px 1px #000000;
    }
  }
`;

const FilmCard = ({ id, onClick, to, image }) => (
  <Poster id={id} src={image} onClick={onClick} to={to} />
);

FilmCard.propTypes = {
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FilmCard;
