import { Fragment } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import Card from 'components/Card';
import Text from 'components/Text';
import Divider from 'components/Divider';
import Marker from 'images/icons/map-marker.svg';
import formatDay from './formatDay';

const Timeslot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #993533;
  color: white;
  margin-top: 2px;
  margin-left: 2px;
  width: 60px;
  height: 40px;
`;

const FilmShowings = ({ showings }) =>
  Object.entries(showings).map(([date, dayOfShowings]) => (
    <Fragment key={date}>
      <Card className="mb-2 w-full" backgroundColor="light">
        <Text className="font-medium capitalize text-lg" color="dark">
          {formatDay(date)}
        </Text>
      </Card>
      <DayOfShowings showings={dayOfShowings} />
    </Fragment>
  ));

const DayOfShowings = ({ showings }) =>
  Object.entries(showings).map(([cinema, cinemaShowings]) => (
    <Card key={cinema.toLowerCase()} className="mb-2 w-full">
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        <Text className="text-base mr-2" color="light">
          {cinema}
        </Text>
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
            cinema,
          )}&travelmode=transit`}
          target="_blank"
          rel="noreferrer noopener"
          css="height: 30px"
        >
          <Marker
            css={`
              width: 30px;
              height: 30px;
              color: #ab2523;
            `}
          />
        </a>
      </div>
      {Object.entries(cinemaShowings).map(([version, versionShowings]) => (
        <Fragment key={version}>
          <Divider className="my-4" />
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            <Text className="text-base mr-4" color="light">
              {version.toUpperCase()}
            </Text>
            <div
              css={`
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: flex-end;
                position: relative;
                top: -2px;
              `}
            >
              {sortBy(versionShowings, 'time').map((showing) => (
                <a
                  css={`
                    color black;
                    text-decoration: none;
                  `}
                  key={showing.id}
                  rel="noopener"
                  target="_blank"
                  href={showing.booking_url}
                >
                  <Timeslot>{dayjs(showing.time).format('HH:mm')}</Timeslot>
                </a>
              ))}
            </div>
          </div>
        </Fragment>
      ))}
    </Card>
  ));

export default FilmShowings;
