import classnames from 'classnames';

const DARK_THEME = 'dark';
const LIGHT_THEME = 'light';

const Button = ({
  children,
  className,
  theme = DARK_THEME,
  fluid = false,
  ...props
}) => (
  <button
    type="button"
    className={classnames(
      'font-medium py-2 px-4 rounded',
      {
        'bg-neutral-700 hover:bg-neutral-900 text-white': theme === DARK_THEME,
        'bg-neutral-200 hover:bg-neutral-400 text-neutral-900':
          theme === LIGHT_THEME,
        'w-full': fluid,
      },
      className,
    )}
    {...props}
  >
    {children}
  </button>
);

export default Button;
