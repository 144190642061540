import { useState, useEffect } from 'react';
import axios from 'axios';

export const useQuery = ({ url, method = 'get', params = {}, listen = [] }) => {
  const [queryState, setQueryState] = useState({
    loading: false,
    success: false,
    error: false,
  });

  const performRequest = () => {
    const requester = axios[method.toLowerCase()];

    setQueryState((state) => ({ ...state, loading: true }));

    requester(url, { params })
      .then(({ data, status }) => {
        setQueryState((state) => ({
          ...state,
          data,
          status,
          loading: false,
          success: true,
          error: false,
        }));
      })
      .catch(() => {
        setQueryState((state) => ({
          ...state,
          loading: false,
          success: false,
          error: true,
        }));
      });
  };

  useEffect(performRequest, listen);

  return queryState;
};

export default useQuery;
