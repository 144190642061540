import { useEffect } from 'react';

const Backdrop = ({ onClick }) => (
  <div
    onClick={onClick}
    className="fixed top-0 left-0 h-full w-full bg-black opacity-80 z-30"
  />
);

export const DrawerTitle = ({ children }) => (
  <div className="text-xl font-medium text-white mb-2">{children}</div>
);

const Drawer = ({ onHide, children }) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  return (
    <>
      <Backdrop onClick={onHide} />
      <div className="fixed bottom-0 rounded-t w-full z-50 text-white bg-neutral-500 p-4">
        {children}
      </div>
    </>
  );
};

const DrawerContainer = ({ show = false, ...props }) => {
  if (!show) return null;

  return <Drawer show={show} {...props} />;
};

export default DrawerContainer;
