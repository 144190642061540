import classnames from 'classnames';

const BACKGROUND_COLOR_LIGHT = 'light';
const BACKGROUND_COLOR_DARK = 'dark';

const Card = ({
  backgroundColor = BACKGROUND_COLOR_DARK,
  className,
  children,
}) => (
  <div
    className={classnames(
      'p-4 border-box',
      {
        'bg-neutral-300': backgroundColor === BACKGROUND_COLOR_LIGHT,
        'bg-[#2d2d2d]': backgroundColor === BACKGROUND_COLOR_DARK,
      },
      className,
    )}
  >
    {children}
  </div>
);

export default Card;
