import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { track, eventNames } from 'modules/tracking/tracking';
import { useQuery } from 'modules/hooks/useQuery';
import xor from 'lodash/xor';
import Drawer, { DrawerTitle } from 'components/Drawer';
import Loader from 'components/Loader';
import Button from 'components/Button';
import CaretIcon from 'images/icons/caret-symbol.svg';
import { getDateFilterOptions } from './helpers';

const FilterButton = ({ onClick, className, children }) => {
  return (
    <div
      className={classNames(
        'text-neutral-100 border border-gray-500 rounded w-fit px-3 cursor-pointer capitalize whitespace-nowrap',
        className,
      )}
      onClick={onClick}
    >
      <div className="flex items-center">
        {children}
        <CaretIcon className="mt-[3px] ml-2 w-3 h-3 rotate-180 fill-white" />
      </div>
    </div>
  );
};

const RadioButton = ({ selected, onSelect, children }) => (
  <div
    onClick={onSelect}
    className={classNames(
      'py-4 mb-4 border rounded text-center border-white capitalize md:hover:bg-neutral-600 cursor-pointer',
      { 'bg-white text-gray-500': selected },
    )}
  >
    {children}
  </div>
);

const DrawerBodyWrapper = ({ children }) => (
  <div className="flex flex-col space-evenly h-full overflow-auto max-h-80 p-5">
    {children}
  </div>
);

const DATE_FILTER = 'date_filter';
const CINEMAS_FILTER = 'cinemas_filter';
const CARDS_FILTER = 'cards_filter';

const DateFilter = ({ filters, setFilters, options, hideDrawer }) => (
  <>
    <DrawerTitle>Sélectionner une date</DrawerTitle>
    <DrawerBodyWrapper>
      {options.map((option) => {
        const selectedOption = option.value === filters.date;

        return (
          <RadioButton
            key={option.id}
            selected={selectedOption}
            onSelect={() => {
              track({
                name: eventNames.SELECT_DATE_FILTER,
                selected_option: option.label,
              });
              setFilters((state) => ({ ...state, date: option.value }));
              hideDrawer();
            }}
          >
            {option.label}
          </RadioButton>
        );
      })}
    </DrawerBodyWrapper>
  </>
);

const toggleOptionInFilterArray = (filterArray, option) =>
  xor(filterArray, [option]);

const MultipleChoiceFilter = ({
  options,
  filter,
  setFilter,
  hideDrawer,
  trackingEventName,
}) => (
  <>
    <DrawerBodyWrapper>
      {options.map((option) => {
        const selectedOption = filter.includes(option.id);

        return (
          <RadioButton
            key={option.id}
            selected={selectedOption}
            onSelect={() => {
              track({
                name: trackingEventName,
                selected_option: option.name,
              });
              setFilter(toggleOptionInFilterArray(filter, option.id));
            }}
          >
            {option.name}
          </RadioButton>
        );
      })}
    </DrawerBodyWrapper>
    <Button fluid className="mt-5" onClick={hideDrawer}>
      Sélectionner
    </Button>
  </>
);

const CinemasFilter = ({ filters, setFilters, hideDrawer }) => {
  const {
    loading,
    success,
    data: cinemas = [],
  } = useQuery({
    url: '/api/cinemas.json',
  });

  return (
    <>
      <DrawerTitle>Sélectionner vos cinémas</DrawerTitle>
      {loading && <Loader className="w-12 relative top-1/2 mx-auto" />}
      {success && (
        <MultipleChoiceFilter
          options={cinemas}
          filter={filters.cinemas}
          trackingEventName={eventNames.SELECT_CINEMAS_FILTER}
          setFilter={(cinemasFilter) =>
            setFilters((state) => ({ ...state, cinemas: cinemasFilter }))
          }
          hideDrawer={hideDrawer}
        />
      )}
    </>
  );
};

const CardsFilter = ({ filters, setFilters, hideDrawer }) => {
  const {
    loading,
    success,
    data: cards = [],
  } = useQuery({
    url: '/api/cards.json',
  });

  return (
    <>
      <DrawerTitle>Sélectionner vos cartes</DrawerTitle>
      {loading && <Loader className="w-12 relative top-1/2 mx-auto" />}
      {success && (
        <MultipleChoiceFilter
          options={cards}
          filter={filters.cards}
          trackingEventName={eventNames.SELECT_CARDS_FILTER}
          setFilter={(cardsFilter) =>
            setFilters((state) => ({ ...state, cards: cardsFilter }))
          }
          hideDrawer={hideDrawer}
        />
      )}
    </>
  );
};

const Filters = ({ filters, setFilters }) => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const hideDrawer = () => setSelectedFilter();

  const dateFilterOptions = useMemo(getDateFilterOptions, []);

  const { shortLabel: selectedDateShortLabel } = dateFilterOptions.find(
    ({ value }) => filters.date === value,
  );
  const selectedCinemasShortLabel = `Cinémas${
    filters.cinemas.length > 0 ? ` (${filters.cinemas.length})` : ''
  }`;
  const selectedCardsShortLabel = `Cartes${
    filters.cards.length > 0 ? ` (${filters.cards.length})` : ''
  }`;

  return (
    <>
      <div className="fixed z-10 w-full bg-neutral-700 py-3 px-1 flex justify-start overflow-x-auto">
        <FilterButton
          className="m-auto"
          onClick={() => {
            setSelectedFilter(DATE_FILTER);
            track({ name: eventNames.CLICK_ON_DATE_FILTER });
          }}
        >
          {selectedDateShortLabel}
        </FilterButton>
        <FilterButton
          className="m-auto"
          onClick={() => {
            setSelectedFilter(CINEMAS_FILTER);
            track({ name: eventNames.CLICK_ON_CINEMAS_FILTER });
          }}
        >
          {selectedCinemasShortLabel}
        </FilterButton>
        <FilterButton
          className="m-auto"
          onClick={() => {
            setSelectedFilter(CARDS_FILTER);
            track({ name: eventNames.CLICK_ON_CARDS_FILTER });
          }}
        >
          {selectedCardsShortLabel}
        </FilterButton>
      </div>
      <div className="pb-[50px]" />
      <Drawer show={Boolean(selectedFilter)} onHide={hideDrawer}>
        {selectedFilter === DATE_FILTER && (
          <DateFilter
            filters={filters}
            setFilters={setFilters}
            hideDrawer={hideDrawer}
            options={dateFilterOptions}
          />
        )}
        {selectedFilter === CINEMAS_FILTER && (
          <CinemasFilter
            filters={filters}
            setFilters={setFilters}
            hideDrawer={hideDrawer}
          />
        )}
        {selectedFilter === CARDS_FILTER && (
          <CardsFilter
            filters={filters}
            setFilters={setFilters}
            hideDrawer={hideDrawer}
          />
        )}
      </Drawer>
    </>
  );
};

export default Filters;
