import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { track, eventNames } from 'modules/tracking/tracking';

const useTrackPageChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    track({ name: eventNames.PAGE_CHANGE, location: location.pathname });
  }, [pathname]);
};

export default useTrackPageChange;
