import Text from 'components/Text';
import TwitterIcon from 'images/icons/twitter-square.svg';

const Footer = () => (
  <div className="w-full p-5 flex justify-between bg-[#222222]">
    <Text color="light">© BladeWatcher</Text>
    <a
      css={`
        display: flex;
        align-items: center;
      `}
      href="https://twitter.com/th_mdo"
      target="_blank"
    >
      <Text
        css={`
          margin-right: 8px;
        `}
        color="light"
      >
        th_mdo
      </Text>
      <TwitterIcon
        css={`
          width: 20px;
          height: 20px;
          fill: #eeeeee;
        `}
      />
    </a>
  </div>
);

export default Footer;
