import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);

export default (date) =>
  dayjs(date).calendar(null, {
    sameDay: "[aujourd'hui]",
    nextDay: '[demain]',
    nextWeek: 'dddd D MMMM',
    lastDay: '[hier]',
    lastWeek: '',
    sameElse: '',
  });
