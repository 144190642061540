import { useQuery } from 'modules/hooks/useQuery';

export const useFilms = ({ filters }) => {
  const debugFetchAll = !!localStorage?.getItem('debug:fetch-all-showings');

  const params = {
    future: !debugFetchAll,
    ...filters,
  };

  const state = useQuery({
    url: '/api/films.json',
    params,
    listen: [filters],
  });

  return { ...state, films: state.data };
};

export default useFilms;
