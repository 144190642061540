import classnames from 'classnames';
import SpinnerThird from 'images/icons/spinner-third.svg';

const Loader = ({ className }) => (
  <SpinnerThird
    className={classnames('h-fit fill-white animate-spin', className)}
  />
);

const heightProp = 'h-[calc(100%-134px)]';
const heightPropWithFilterBar = 'h-[calc(100%-184px)]';

export const PageLoader = ({ className, filterBarPresent = false }) => {
  return (
    <div
      className={classnames('flex items-center justify-center', {
        [heightProp]: !filterBarPresent,
        [heightPropWithFilterBar]: filterBarPresent,
      })}
    >
      <Loader className={classnames('w-16', className)} />
    </div>
  );
};

export default Loader;
