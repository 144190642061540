import { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalContainer = ({ children }) => {
  const [state, setState] = useState({
    focusedId: '',
    opened: false,
  });

  const closeModal = () => setState({ opened: false });

  const openModal = ({ id }) => setState({ focusedId: id, opened: true });

  return (
    <ModalContext.Provider
      value={{
        ...state,
        closeModal,
        openModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
