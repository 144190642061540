import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import caret from 'images/icons/caret-symbol.svg';

const TitleWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: #222222;
  text-align: center;
  height: 70px;
  z-index: 10;
  top: 0;
  word-spacing: -12px;
`;

const Caret = styled(caret)`
  fill: white;
  transform: rotate(-90deg);
  width: 20px;
  height: auto;
  position: absolute;
  left: 16px;
`;

const TitleBar = ({ onClick }) => {
  const location = useLocation();
  const isOnHome = location.pathname === '/';

  return (
    <>
      <TitleWrapper onClick={onClick}>
        {!isOnHome && <Caret />}
        <div
          css={`
            font-family: 'BladeRunner';
            color: #e4e4e4;
            font-size: 28px;
            width: fit-content;
          `}
        >
          blade watcher
        </div>
      </TitleWrapper>
      <div className="pb-[70px]" />
    </>
  );
};

export default TitleBar;
