import { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { BrowserContextProvider } from 'modules/browserContext';
import { ModalContainer } from 'modules/modal/ModalContainer';
import useTrackPageChange from 'modules/hooks/useTrackPageChange';
import { ScrollRestorationProvider } from 'modules/contexts/scrollRestoration';
import TitleBar from './bars/TitleBar';
import Filters from './filters/Filters';
import FilmsIndex from './films/FilmsIndex';
import FilmDetails from './film-details/FilmDetails';
import Footer from './Footer';
import LogoUrl from 'images/logo.png';

const FilmViews = () => {
  useTrackPageChange();

  const [filters, setFilters] = useState({
    date: null,
    cinemas: [],
    cards: [],
  });
  const navigate = useNavigate();

  return (
    <>
      <TitleBar onClick={() => navigate('/')} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Filters filters={filters} setFilters={setFilters} />
              <FilmsIndex filters={filters} />
            </>
          }
        />
        <Route path="/film/:id" element={<FilmDetails filters={filters} />} />
      </Routes>
      <Footer />
    </>
  );
};

const App = () => (
  <Router>
    <Helmet>
      <title>Bladewatcher</title>
      <meta property="og:title" content="Bladewatcher" />
      <meta
        name="description"
        content="Toutes vos séances de cinéma sur Paris"
      />
      <meta
        property="og:description"
        content="Toutes vos séances de cinéma sur Paris"
      />
      <meta property="og:image" content="/logo.png" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://bladewatcher.com" />
      <meta property="og:site_name" content="Bladewatcher" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@th_mdo" />
      <meta name="twitter:image" content="/logo.png" />
    </Helmet>
    <ModalContainer>
      <BrowserContextProvider>
        <ScrollRestorationProvider>
          <FilmViews />
        </ScrollRestorationProvider>
      </BrowserContextProvider>
    </ModalContainer>
  </Router>
);

export default App;
