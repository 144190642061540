import { useLayoutEffect, useState, useContext, createContext } from 'react';

export const ScrollRestorationContext = createContext();

export const ScrollRestorationProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const saveScrollPosition = () => {
    setScrollPosition(window.scrollY);
  };
  const restoreScrollPosition = () => {
    window.scrollTo(0, scrollPosition);
  };

  return (
    <ScrollRestorationContext.Provider
      value={{ saveScrollPosition, restoreScrollPosition }}
    >
      {children}
    </ScrollRestorationContext.Provider>
  );
};
