import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import Mixpanel from 'mixpanel-browser';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import './application.scss';

import App from 'pages/App';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  Sentry.init({
    dsn: 'https://58598178a87c4cb48aba2e0491b678d1@o191897.ingest.sentry.io/5328278',
  });
  Mixpanel.init('466d37f54921bd17636bacf4fd6596c0');
}

dayjs.locale('fr');

document.addEventListener('DOMContentLoaded', () => {
  const root = createRoot(document.getElementById('root'));
  root.render(<App />);
});
