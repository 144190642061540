import dayjs from 'dayjs';
import range from 'lodash/range';

const getDateLabel = (daysOnward) =>
  dayjs().add(daysOnward, 'day').format('dddd D MMMM');

const getDateShortLabel = (daysOnward) =>
  dayjs().add(daysOnward, 'day').format('dddd');

const getDateValue = (daysOnward) =>
  dayjs().add(daysOnward, 'day').format('YYYY/MM/DD');

export const getDateFilterOptions = () => [
  {
    label: 'Les 7 prochains jours',
    shortLabel: '+ 7 jours',
    value: null,
  },
  { label: "Aujourd'hui", shortLabel: "Aujourd'hui", value: getDateValue(0) },
  { label: 'Demain', shortLabel: 'Demain', value: getDateValue(1) },
  ...range(2, 7).map((daysOnward) => ({
    label: getDateLabel(daysOnward),
    shortLabel: getDateShortLabel(daysOnward),
    value: getDateValue(daysOnward),
  })),
];
