import { createContext, useContext } from 'react';
import MobileDetect from 'mobile-detect';

const mobileDetect = new MobileDetect(window.navigator.userAgent);

export const BrowserContext = createContext({
  environment: mobileDetect,
  isMobile: mobileDetect.mobile() !== null,
});

export const BrowserContextProvider = ({ children }) => (
  <BrowserContext.Provider
    value={{
      environment: mobileDetect,
      isMobile: mobileDetect.mobile() !== null,
    }}
  >
    {children}
  </BrowserContext.Provider>
);

export const useBrowser = () => {
  return useContext(BrowserContext);
};

export default BrowserContext;
